function google_map_init(lat,lng, id) {
  var latlng = new google.maps.LatLng(lat,lng);/* 座標 */

  /*地図のオプションを設定する*/
  var myfooterOptions = {
    zoom: 15, /*拡大比率*/
    center: latlng,/*表示枠内の中心点*/
    mapTypeControlOptions: { mapTypeIds: ['style', google.maps.MapTypeId.ROADMAP] },/*表示タイプの指定*/
    disableDefaultUI: true,/*trueにするとコントローラーが全部消える*/
    // zoomControl: true,/*ズームイン・ズームアウトコントローラーの表示*/
  };

  var map = new google.maps.Map(document.getElementById(id), myfooterOptions);

  /*アイコン設定*/
  // var icon = {
  //   url : '../img/icon-flag.png',
  //   scaledSize : new google.maps.Size(20, 30)
  //   // ↑ここで画像のサイズを指定
  // }

  var markerfooterOptions = {
    position: latlng,/*アイコンの表示場所指定：表示枠内の中心点*/
    map: map,
    // icon: icon,
    // label: '須藤本家',
  };
  var marker = new google.maps.Marker(markerfooterOptions);
}
