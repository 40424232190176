$( function() {
  scroll_init();
  if($('.js-pageTop').length) pagetop_init();
  if($(".js-slick").length) slick_init();
  if($('.js-nav-trigger').length) nav_init();
  // if($('#js-footer-map').length) google_map_init(35.3011544,140.069625,'js-footer-map');
  if($('.form-cont span.error').length) {
    $('.form-cont span.error').each(function() {
      $(this).parents('dd').find('input, textarea').addClass('is-error');
    });
  }
  if($('.form-btn input').length) {
    var val = $('.form-btn input').val();
    $('.form-btn .button').append(val);
  }
});

function scroll_init() {
  $('a[href^=#]').click(function(){
    var speed = 500;
    var href= $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top -120;
    $("html, body").animate({scrollTop:position}, speed, "swing");
    return false;
  });
}

function slick_init() {
  $('.js-slick').slick({
    // アクセシビリティ。左右ボタンで画像の切り替えをできるかどうか
    accessibility: false,
    // 自動再生。trueで自動再生される。
    autoplay: true,
    // 自動再生で切り替えをする時間
    autoplaySpeed: 3000,
    // 自動再生や左右の矢印でスライドするスピード
    speed: 400,
    // 自動再生時にスライドのエリアにマウスオンで一時停止するかどうか
    pauseOnHover: true,
    // 自動再生時にドットにマウスオンで一時停止するかどうか
    pauseOnDotsHover: true,
    // 切り替えのアニメーション。ease,linear,ease-in,ease-out,ease-in-out
    cssEase: 'ease-in-out',
    // 画像下のドット（ページ送り）を表示
    dots: true,
    // ドットのclass名をつける
    dotsClass: 'dot-class',
  });
}


function pagetop_init() {
  var topBtn = $('.js-pageTop');
  topBtn.hide();
  //スクロールが300に達したらボタン表示
  $(window).scroll(function () {
      if ($(this).scrollTop() > 300) {
          topBtn.fadeIn();
      } else {
          topBtn.fadeOut();
      }
  });
  //スクロールしてトップ
  topBtn.click(function () {
      $('body,html').animate({
          scrollTop: 0
      }, 500);
      return false;
  });
}

function nav_init() {
  $('.js-nav-trigger').on('click', function(){
    $('body').toggleClass('nav-open');
    return false;
  });
}